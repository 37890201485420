export const POINTGROUPS_OVERVIEW = `groups`;
export const POINTGROUPS_VIEWGROUP = `groups/view`;
export const POINTGROUPS_EDITGROUPSETTINGS = `groups/editgroup`;
export const POINTGROUPS_CREATEGROUP = `groups/create`;
export const POINTGROUPS_EDITPOINTS = `groups/editpoints`;

export const DEVICES_VIEWDEVICE = `devices/view`;
export const DEVICES_BACKUP = `devices/backups`;
export const DEVICES_CREATEDEVICE = `devices/create`;
export const DEVICES_EDITDEVICE = `devices/edit`;
export const DEVICES_OVERVIEW = `devices`;

export const LEVELS_CREATE = `levels/create`;
export const LEVELS_EDIT = `levels/edit`;
export const LEVELS_VIEW = `levels/view`;
