import { Permissions } from "./consts";

export const invalidCharacters = /^[^<>&"'\\/:*?|]*$/;
export const pageSizes = [10, 20, 50];
export const commonDebounceDelay = 400;

export const isPathIncluded = (str: string) => {
  return window.location.pathname.includes(str);
};

export const generateQuery = (queries: object) => {
  return Object.getOwnPropertyNames(queries)
    .reduce((prev, curr) => {
      prev.push(
        encodeURIComponent(curr) + "=" + encodeURIComponent(queries[curr]),
      );
      return prev;
    }, [] as string[])
    .join("&");
};

export const hasPermission = (
  permission: Permissions,
  permissions: string[],
) => {
  return permissions.includes(permission);
};
