import { t } from "@lingui/macro";

export enum Permissions {
  ANALYTICS = "analytics",
  COMPANY_ADMIN = "company.admin",
}

export type Pagination = {
  total: number;
  totalPages: number;
  page: number;
  pageSize: number;
};

export type ApiResponse<T> = {
  data: T;
};

export type ApiResponsePaginated<T> = {
  data: T;
  meta: {
    pagination: Pagination;
  };
};

export type GetAllParams = {
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  order?: string;
  nested?: string;
};

export type QueryOptions = {
  enabled: boolean;
};

export const pageSizes = [10, 20, 50];
export const defaultPageSize = 10;
export const invalidCharacters = /^[^<>&"'\\/:*?|]*$/;
export const commonDebounceDelay = 400;
// Presumably point groups won't have more than 30 - 40 points - so we are fetching them all in a single query
// Set maximum amount of points to be fetched per point group query
export const maxPointGroupPoints = 500;

export const defaultTableTexts = {
  activeFiltersText: t`Active filters:`,
  clearAllFiltersButtonText: t`CLEAR`,
  emptyResultsText: t`No results found`,
  filtersSelectedText: t`selected`,
  searchPlaceholderText: t`Search`,
} as const;
