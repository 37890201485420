import { Stack, TopNavigation, TopNavigationItems } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";

import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { CompanySelect } from "../company/company-select";
import { useNavigationPath, usePathNavigator } from "../utils/url";
import { NavMenu } from "./nav-menu";
import { Permissions } from "../consts";
import { hasPermission } from "../helper";
import { useCurrentUserQuery } from "../user/user.queries";
import { apiRequest } from "../auth/api-request";

export function Navbar() {
  const { to, isActive } = useNavigationPath();
  const { data: user } = useCurrentUserQuery();
  const { companyId } = useParams();
  const { withSubUrl } = usePathNavigator();
  const queryClient = useQueryClient();

  useEffect(() => {
    (async () => {
      await apiRequest.refreshAccessToken();
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const items: TopNavigationItems = [
    {
      to: to("devices"),
      label: t`Devices`,
      isActive: isActive("devices"),
    },
    {
      to: to("groups"),
      label: t`Groups`,
      isActive: isActive("groups"),
    },
  ];

  const permissions = user?.permissions || [];

  const menuItems: {
    to: string;
    label: string;
    permission?: Permissions;
  }[] = [
    {
      to: withSubUrl(`https://analytics2.fidelix.com/company/${companyId}`),
      label: t`SCADA`,
      permission: Permissions.ANALYTICS,
    },
    {
      to: withSubUrl(`https://admin.fidelix.com/${companyId}/users`),
      label: t`Access Management`,
      permission: Permissions.COMPANY_ADMIN,
    },
  ].filter((item) => hasPermission(item.permission, permissions));

  return (
    <TopNavigation variant="primary">
      <TopNavigation.Logo menuItems={menuItems} />
      <TopNavigation.Items items={items} />
      <TopNavigation.Actions>
        <Stack axis="x" spacing="medium" align="center">
          <CompanySelect />
          <NavMenu />
        </Stack>
      </TopNavigation.Actions>
    </TopNavigation>
  );
}
